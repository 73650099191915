import ToolboxLink from 'src/components/streaming/streamingtoolbox/ToolboxLink';
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
import IFilmnytt from 'src/lib/types/Filmnytt';
import IStreamingToolbox from 'src/lib/types/StreamingToolbox';

//#region [Props]
type ToolboxProps = {
	toolbox?: IStreamingToolbox;
};
//#endregion

//#region [Component]
export default function Toolbox({ toolbox }: ToolboxProps) {
	if ((toolbox?.links?.length ?? 0) === 0) {
		return null;
	}
	return <SToolbox>
		{toolbox!.links!.map((link, index) => {
			return <SToolboxLink
				key={link._key as string}
				link={link}
				index={index} />
		})}
	</SToolbox>
}
//#endregion

//#region [Styles]
const SToolbox = styled.div`
	display: flex;
	margin-top: 2em;
	overflow-x: auto;
	gap: 15px;


	@media ${theme.mq.mobile} {
		margin-right: -10px;
	}

	@media ${theme.mq.desktop} {
		justify-content: center;
	}
`;

const SToolboxLink = styled(ToolboxLink)`
	flex: 0 0 25vw;
	@media ${theme.mq.desktop} {
		flex: 0 0 142px;
	}
`;
//#endregion