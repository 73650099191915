import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import CmsImage from 'src/components/images/CmsImage';
import ToolboxImage from "src/components/streaming/streamingtoolbox/ToolboxImage";
import styled from 'src/lib/styles/css';
import { DocType_Filmnytt } from 'src/lib/types/Filmnytt';
import { IArticleWithOverriddenTitle, ILinkWithImage } from 'src/lib/types/StreamingToolbox';
import { getUrlForDoctype } from 'src/lib/types/sanity';

//#region [Props]
type ToolboxLinkProps = {
	link: ILinkWithImage | IArticleWithOverriddenTitle;
	className?: string;
	index: number;
};
//#endregion

//#region [Component]
export default function ToolboxLink({ link, className, index }: ToolboxLinkProps) {
	let _linkUrl: string | null;
	let text: string;
	switch (link._type) {
		case "articleWithOverriddenTitle":
			const artLnk = (link as IArticleWithOverriddenTitle);
			_linkUrl = getUrlForDoctype(DocType_Filmnytt, artLnk.slug.current, true);
			text = artLnk.titleOverride ?? link.title;
			break;
		default:
			const lnk = (link as ILinkWithImage);
			_linkUrl = lnk.url;
			text = link.title;
	}


	return <SToolboxLink to={_linkUrl!} className={className} onClick={() => trackToolboxItem(index, _linkUrl!)}>
		<ToolboxImage type={link.toolboxImage} />
		<SLinkText>{text}</SLinkText>
	</SToolboxLink>;
}
//#endregion

//#region [Other]
function trackToolboxItem(index: number, url: string) {
	window?.dataLayer?.push({
		'event': 'fw_SGToolbox',
		'eventCategory': 'SG Verktøykasse',
		'eventAction': url,
		'eventLabel': `Plassering ${index + 1}`
	});
}

//#endregion

//#region [Styles]
const SToolboxLink = styled(FilmwebLink)`
	text-align: center;
	overflow: hidden;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

const SLinkText = styled.span`
	display: block;
	padding: 5px;
	font-size: 0.8rem;
	font-weight: bold;
	color: var(--backgroundcolor);
	line-height: 1.1;
`;
//#endregion