import { Filmomtale } from "@filmweb/sanity-types";
import IFilmnytt from "src/lib/types/Filmnytt";
import { Reference } from "sanity";

const DocType_Filmomtale = "filmomtale";

export { DocType_Filmomtale };

interface IFilmomtale<ReferenceIsExpanded extends boolean = boolean> extends Filmomtale {
	_type: "filmomtale";
	otherRelations: ReferenceIsExpanded extends true ? IFilmnytt[] : Reference[];
}

export type { IFilmomtale };
